import * as React from 'react';
import { Link } from 'gatsby';

import styles from './go-to-link.module.css';

export const GoToLink = ({
  className,
  ref,
  ...props
}: React.ComponentProps<typeof Link>) => {
  const classNames = [styles.gotoLink, className].filter(c => c).join(' ');

  return <Link className={classNames} {...props} />;
};
