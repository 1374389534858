import * as React from 'react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import { LocationsPageQuery } from '../../graphql-types';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { GoToLink } from '../components/GoToLink';
import SEO from '../components/seo';

interface LocationsPageProps {
  data: LocationsPageQuery;
}

export const query = graphql`
  query LocationsPage {
    allContentfulLocation {
      edges {
        node {
          id
          slug
          name
          title
          address {
            streetAddress
            secondaryAddress
            city
            state
            zipcode
          }
          snippet {
            snippet
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1076, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    allContentfulServiceArea {
      edges {
        node {
          title
          slug
          snippet {
            snippet
          }
          id
          body {
            json
          }
        }
      }
    }
  }
`;

export default ({ data }: LocationsPageProps) => {
  const locations = data.allContentfulLocation.edges;
  const serviceAreas = data.allContentfulServiceArea.edges;

  return (
    <Layout>
      <SEO title="Locations" />

      <Section>
        <Container>
          <h1>Locations</h1>

          {locations
            .map(l => l.node)
            .map(l => (
              <div key={l.id} style={{ marginTop: 'var(--spacing-120)' }}>
                {l.image?.localFile?.childImageSharp?.fluid && (
                  <div>
                    <Img
                      fluid={
                        l.image.localFile.childImageSharp.fluid as FluidObject
                      }
                      alt={l.title as string}
                      style={{
                        borderRadius: 8,
                        marginBottom: 'var(--spacing-90)',
                      }}
                    />
                  </div>
                )}

                <h2>{l.title}</h2>

                {l.snippet?.snippet && <p>{l.snippet.snippet}</p>}
                <GoToLink to={`/locations/${l?.slug as string}`}>
                  Get more details
                </GoToLink>
              </div>
            ))}

          {serviceAreas.length > 0 && (
            <>
              <hr style={{ margin: 'var(--spacing-90) 0' }} />
              {serviceAreas
                .map(s => s.node)
                .map(s => (
                  <div key={s.id} style={{ marginTop: 'var(--spacing-90)' }}>
                    <h2>{s.title}</h2>{' '}
                    {s.snippet?.snippet && <p>{s.snippet.snippet}</p>}
                    <GoToLink to={`/service-areas/${s?.slug as string}`}>
                      Get more details
                    </GoToLink>
                  </div>
                ))}
            </>
          )}
        </Container>
      </Section>
    </Layout>
  );
};
